<template>
  <v-col cols="12">
    <record-fish ref="recordFish" @done="loadListView()" />
    <tab-menu
      :tabs="tabs"
      @add="addNewRecord"
      @type="(value) => (tabSelected = value)"
      ref="tabMenu"
    >
      <template v-slot:tab-items>
        <v-tab-item v-for="tab in tabs" :key="tab">
          <v-card flat>
            <v-card-text>
              <list-view
                :title="tab"
                :headers="headers[String(tab).toLowerCase()]"
                :records="docs[String(tab).toLowerCase()]"
                :async-loading="loading"
                :footer-options="footer[String(tab).toLowerCase()]"
                :total-docs="total[`${String(tab).toLowerCase()}Docs`]"
                @onPaginate="(v) => (options = v)"
              >
                <template slot="list-item" slot-scope="{ row }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        color="primary"
                        fab
                        dark
                        class="mr-1"
                        v-bind="attrs"
                        v-on="on"
                        @click="openEntryDialog(row)"
                      >
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </template>
              </list-view>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </template>
    </tab-menu>
  </v-col>
</template>

<script>
import { mapActions } from 'vuex';
import TabMenu from '@/components/appComponents/TabMenu.vue';
import ListView from '@/components/appComponents/ListView.vue';
import RecordFish from '@/views/users/forms/RecordFish.vue';
import { RECORD_TYPES } from '../../helpers/constants';
export default {
  name: 'records',
  components: {
    ListView,
    TabMenu,
    RecordFish
  },
  data: () => ({
    loading: false,
    onRecord: false,
    tagData: {},
    total: {
      tagsDocs: 0,
      releasesDocs: 0,
      recapturesDocs: 0,
      juvenilesDocs: 0
    },
    footer: {
      tags: {
        pageCount: 0,
        showFirstLastPage: true
      },
      recaptures: {
        pageCount: 0,
        showFirstLastPage: true
      },
      releases: {
        pageCount: 0,
        showFirstLastPage: true
      },
      juveniles: {
        pageCount: 0,
        showFirstLastPage: true
      }
    },
    headers: {
      recaptures: headers.recapture,
      tags: headers.tag,
      releases: headers.release,
      juveniles: headers.tag
    },
    docs: {
      recaptures: [],
      tags: [],
      releases: [],
      juveniles: []
    },
    options: {
      recaptures: null,
      tags: null,
      releases: null,
      juveniles: null
    },
    tabSelected: 'Tags',
    legacyId: null,
    message: null
  }),
  async mounted() {
    this.$emit('loaded');
  },
  watch: {
    options: {
      handler: 'loadListView'
    },
    tabSelected: {
      handler: 'loadListView'
    }
  },
  computed: {
    tabs() {
      return RECORD_TYPES;
    }
  },
  methods: {
    async loadListView() {
      this.loading = true;
      const event = String(this.tabSelected).toLowerCase();
      this.docs[event] = [];
      const { page, itemsPerPage: limit } = this.options || {
        page: 1,
        itemsPerPage: 10
      };
      const response = await this[`get${this.tabSelected}`]({
        legacyId: this.legacyId,
        page,
        limit
      });
      if (response?.data[`${event}ById`]) {
        const { docs, totalPages, page, totalDocs } =
          response.data[`${event}ById`] || {};
        this.docs[event] = this.modifyArray(docs);
        this.footer[event].pageCount = totalPages;
        this.footer[event].page = page;
        this.total[`${event}Docs`] = totalDocs;
      }
      this.loading = false;
    },
    loadEntries(legacyId) {
      this.legacyId = legacyId;
      setTimeout(async () => {
        await this.loadListView();
        this.$refs.tabMenu && this.$refs.tabMenu.getTotalCount(legacyId);
      }, 50);
    },
    async openEntryDialog(item) {
      this.onRecord = true;
      const data = { userId: this.legacyId, record: item };
      (await this.$refs.recordFish) &&
        this.$refs.recordFish.init(data, this.tabSelected);
    },
    async addNewRecord() {
      const data = { userId: this.legacyId, record: null };
      this.onRecord = true;
      (await this.$refs.recordFish) &&
        this.$refs.recordFish.init(data, this.tabSelected);
    },
    modifyArray(array) {
      return array?.map((item) => {
        item.captain.fullName =
          item?.captain?.firstName && item?.captain?.lastName
            ? `${item?.captain?.firstName} ${item?.captain?.lastName}`
            : '';
        item.angler.fullName =
          item?.angler?.firstName && item?.angler?.lastName
            ? `${item?.angler?.firstName} ${item?.angler?.lastName}`
            : '';
        return item;
      });
    },
    ...mapActions('query', [
      'getTags',
      'getRecaptures',
      'getReleases',
      'getJuveniles'
    ])
  }
};

const headers = {
  tag: [
    {
      text: 'Tag Number',
      align: 'start',
      sortable: true,
      value: 'number'
    },
    {
      text: 'Angler',
      align: 'start',
      sortable: true,
      value: 'angler.fullName'
    },
    {
      text: 'Captain',
      align: 'start',
      sortable: true,
      value: `captain.fullName`
    },
    {
      text: 'Ocean',
      align: 'start',
      sortable: true,
      value: 'capture.ocean'
    },
    {
      text: 'Species',
      align: 'start',
      sortable: true,
      value: 'capture.fish.specie.name'
    },
    { text: 'Actions', value: 'actions', sortable: false }
  ],
  release: [
    {
      text: 'Angler',
      align: 'start',
      sortable: true,
      value: 'angler.fullName'
    },
    {
      text: 'Captain',
      align: 'start',
      sortable: true,
      value: 'captain.fullName'
    },
    {
      text: 'Ocean',
      align: 'start',
      sortable: true,
      value: 'capture.ocean'
    },
    {
      text: 'Species',
      align: 'start',
      sortable: true,
      value: 'capture.fish.specie.name'
    },
    { text: 'Actions', value: 'actions', sortable: false }
  ],
  recapture: [
    {
      text: 'Prior Tag Number',
      align: 'start',
      sortable: true,
      value: 'priorTag.priorTagNumber'
    },
    {
      text: 'New Tag Number',
      align: 'start',
      sortable: true,
      value: 'number'
    },
    {
      text: 'Angler',
      align: 'start',
      sortable: true,
      value: 'angler.fullName'
    },
    {
      text: 'Captain',
      align: 'start',
      sortable: true,
      value: 'captain.fullName'
    },
    {
      text: 'Ocean',
      align: 'start',
      sortable: true,
      value: 'capture.ocean'
    },
    {
      text: 'Species',
      align: 'start',
      sortable: true,
      value: 'capture.fish.species.name'
    },
    { text: 'Actions', value: 'actions', sortable: false }
  ]
};
</script>

<style scoped>
.v-card .v-card__text {
  padding: 0;
}
</style>
